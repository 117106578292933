import React from "react"
import { OAS3ComponentWrapFactory } from "../helpers"

export default OAS3ComponentWrapFactory((props) => {
  const { Ori } = props

  return <span style={{ paddingLeft: "10px" }}>
    <Ori {...props} />
    <small style={{ backgroundColor: "#02A879" }}>
      <pre className="version">OAS3</pre>
    </small>
  </span>
})
